import { debounce } from 'lodash';
import { trackSegmentEvent, getContextProperties, getElementProperties } from '~/components/shared/tracking/segmentAnalytics';

// these need to be outside the controllerElement loop scope in order to keep track of data selected from the inputs
const submitBtns = document.querySelectorAll('[data-target="submit-btn"]');
let apiSelection = null;
let locationSelection = null;
const directoryInput = document.querySelector('[data-search-input="directory"]');
const locationInput = document.querySelector('[data-search-input="city"]');

const defaultSuggestions = [
  {
    "type": "keyword",
    "name": "Urgent Care",
    "id": "kwidx_provider_type_uc",
  },
  {
    "type": "service",
    "name": "Rapid COVID test",
    "id": "Olq1m9,Ord20g_rapid_covid_test_urgent_care",
  },
  {
    "type": "service",
    "name": "COVID test",
    "id": "9nvZo9,Olq1m9,Ord20g,p3MME9,pPooAg,pvN7yp_covid_test_urgent_care",
  },
  {
    "type": "service",
    "name": "Flu Shot",
    "id": "n9wG9K_flu_shot_urgent_care",
  },
  {
    "type": "symptom",
    "name": "Fever",
    "id": "urgent_care_Fever_Fever",
  },
  {
    "type": "symptom",
    "name": "Urinary tract infection",
    "id": "urgent_care_Uti_Urinary_tract_infection",
  },
  {
    "type": "symptom",
    "name": "Flu, cold, or cough",
    "id": "",
  },
  {
    "type": "symptom",
    "name": "Ear ache",
    "id": "",
  },
  {
    "type": "symptom",
    "name": "Rash",
    "id": "urgent_care_Rash_Rash",
  },
  {
    "type": "symptom",
    "name": "Sore throat",
    "id": "urgent_care_Sore_throat_Sore_throat",
  },
];

// data tracking for autocomplete
submitBtns.forEach(btn => {
  btn.addEventListener('click', (e) => {
    trackSegmentEvent('Directory Search CTA Clicked', {
      ...getContextProperties(),
      ...getElementProperties(btn),
      action: 'clicked',
    });
  });
});
if (directoryInput) {
  directoryInput.addEventListener('click', (e) => {
    trackSegmentEvent('Directory Search Selector Clicked', {
      ...getContextProperties(),
      action: 'clicked',
    });
  });
}
if (locationInput) {
  locationInput.addEventListener('click', (e) => {
    trackSegmentEvent('Directory Search Location Clicked', {
      ...getContextProperties(),
      action: 'clicked',
    });
  });
}

document.querySelectorAll('[data-controller="autocomplete"]')
  .forEach(controllerElement => {
    const idInputTarget = controllerElement.querySelector('[data-autocomplete-target="id-input"]');
    const suggestionsTarget = controllerElement.querySelector('[data-autocomplete-target="suggestions"]');
    const autocompleteInputTarget = controllerElement.querySelector('[data-autocomplete-target="autocomplete-input"]');
    const searchPathValue = controllerElement.dataset.autocompleteSearchPathValue;
    const queryKeyValue = controllerElement.dataset.autocompleteQueryKeyValue;
    const selectedClass = 'results__item--selected';
    let results = [];

    // change this to an env variable
    const baseUrl = "https://solvhealth.com/r/search";
    let type = "";
    let name = "";
    let serviceIds = "";
    let location = "";
    let lat = "";
    let lng = "";
    let state = "";

    const buildQueryString = (query) => `${searchPathValue}?${queryKeyValue}=${query}`;

    const parameterizeQueryString_1 = (string) => {
      let temp = string;
      return temp.replace(/\s+/g, '-').toLowerCase();
    }

    const parameterizeQueryString_2 = (string) => {
      let temp = string;
      return encodeURIComponent(temp).replace('%20', '+');
    }

    const setSearchButtonHref = (href) => {
      // submitBtn.setAttribute('href', href);
      submitBtns.forEach(btn => btn.setAttribute('href', href));
    }

    const buildSRPUrl = () => {

      if (apiSelection !== null) {
        type = apiSelection.dataset.type;
        name = apiSelection.dataset.name;
        serviceIds = apiSelection.dataset.id;
      }

      if (locationSelection !== null) {
        location = locationSelection.dataset.name;
        lat = locationSelection.dataset.coordLat;
        lng = locationSelection.dataset.coordLong;
        state = locationSelection.dataset.state;
      }

      //begin building the url
      let href = `${baseUrl}?patient=adult`;

      if (type === "symptom") {
        href += `&query=${parameterizeQueryString_2(name)}`;
      }
      else if (type === "service") {
        href += `&query=${parameterizeQueryString_2(name)}&service_ids=${parameterizeQueryString_2(serviceIds)}`;
      }
      else if (type === "location") { // the type called location is different from the city selected from the second input
        state = apiSelection.dataset.state;
        href = `https://www.solvhealth.com/${parameterizeQueryString_1(name)}-${state}-${serviceIds}`;
        window.location = href; // redirect to the location page upon selection
      }
      else {
        if (apiSelection !== null)
          href += `&query=${parameterizeQueryString_2(name)}`;
        else if (directoryInput.value !== "") {
          href += `&query=${parameterizeQueryString_2(directoryInput.value)}`;
        }
        else
          href += `&query=Urgent+Care`;
      }

      // add the rest of the parameters
      href += `&time=asap&modality=any`;

      if (location !== "")
        href += `&location=${parameterizeQueryString_2(location)}&lat=${lat}&lng=${lng}`;
      else
        href += `&location=&lat=&lng=`; // when the user is redirected, the solv app will automatically use the user's location in the browser, so we can leave these blank

      if (state !== "")
        href += `&state=${state}`;

      setSearchButtonHref(href);
    }

    // in case the user types in but does not select anything from the menu
    if (directoryInput) {
      directoryInput.addEventListener('input', (e) => {
        buildSRPUrl();
      });
    }
    const closeSuggestionsPanel = () => {
      suggestionsTarget.parentElement.classList.add('autocomplete__results--hidden');
    }

    const onClickOutside = (e) => {
      e.stopPropagation();
      const isOutside = !controllerElement.contains(e.target) && e.target !== controllerElement;
      if (isOutside) {
        closeSuggestionsPanel();
        document.removeEventListener('click', onClickOutside)
      }
    }

    const getSelectedResult = () => {
      if (results.length === 0) {
        return null;
      }
      return suggestionsTarget.querySelector(`.${selectedClass}`);
    }

    const setSelection = (index) => {
      getSelectedResult()?.classList.remove(selectedClass);
      if (index > -1) {
        const newSelection = suggestionsTarget.querySelector(`[data-index='${index}']`);
        newSelection.classList.add(selectedClass);
        idInputTarget.value = newSelection.dataset.id;
        autocompleteInputTarget.value = newSelection.dataset.name;
      }
    }

    const onResultClick = (e) => {
      let selection = e.target;
      // there are divs and img inside the results, so we need to check if the user clicked on those instead of the li
      if (e.target.tagName === 'DIV' || e.target.tagName === 'IMG')
        selection = e.target.parentElement;

      const index = parseInt(selection.dataset.index);
      setSelection(index);

      // we need to set the global variables in order to use them in the buildSRPUrl function for building the url
      if (selection.dataset.location)
        locationSelection = selection;
      else
        apiSelection = selection;

      if (directoryInput) {
        buildSRPUrl();
      }

      closeSuggestionsPanel();
    }

    const openSuggestionsPanel = () => {
      suggestionsTarget.parentElement.classList.remove('autocomplete__results--hidden');
      document.addEventListener('click', onClickOutside)
    }

    const addHandlersToSuggestions = () => {
      suggestionsTarget.querySelectorAll('.results__item')
        .forEach(el => {
          el.addEventListener('click', onResultClick)
          el.addEventListener('keyup', e => {
            if (e.key === 'Enter' || e.key === 'Return') {
              onResultClick(e);
            }
          })
        });
    }

    const buildAPISuggestionHTML = (suggestion, index) => {
      if (suggestion.name === "Urgent Care") {
        return `<li tabindex="0"
        class="results__item"
        data-type="${suggestion.type}"
        data-index="${index}"
        data-id="${suggestion.id}"
        data-name="${suggestion.name}"><img src="https://www.solvhealth.com/images/icons/universal_search/uc.svg"><div>${suggestion.highlight}</div></li>`;
      }
      else if (suggestion.type === "location") {
        return `<li tabindex="0"
      class="results__item"
      data-type="${suggestion.type}"
      data-id="${suggestion.id}"
      data-state="${suggestion.state}"
      data-index="${index}"
      data-name="${suggestion.name}">
      <div><img class="rounded" src=${suggestion.location_images.webp === "/images/Default-Clinic-Photo.svg" ? "https://www.solvhealth.com/images/Default-Clinic-Photo.svg" : suggestion.location_images.webp} alt="img"></div>
      <div>${suggestion.highlight}</div></li>`;
      }
      else {
        return `<li tabindex="0"
      class="results__item"
      data-type="${suggestion.type}"
      data-id="${suggestion.id}"
      data-state="${suggestion.state}"
      data-index="${index}"
      data-name="${suggestion.name}"><div>${suggestion.highlight}</div></li>`;
      }
    }

    const buildLocationSuggestionHTML = (suggestion, index) =>
      `<li tabindex="0"
        class="results__item"
        data-location="true"
        data-state="${suggestion.state_abbr}"
        data-coord-lat="${suggestion.latitude}"
        data-coord-long="${suggestion.longitude}"
        data-id="${suggestion.id}"
        data-index="${index}"
        data-name="${suggestion.name_with_state}"><div>${suggestion.name_with_state}</div></li>`;

    const buildDefaultSuggestionHTML = (suggestion, index) => {
      if (suggestion.name === "Urgent Care") {
        return `<li tabindex="0"
        class="results__item"
        data-type="${suggestion.type}"
        data-index="${index}"
        data-id="${suggestion.id}"
        data-name="${suggestion.name}"><div><img src="https://www.solvhealth.com/images/icons/universal_search/uc.svg" alt="img"></div><div>${suggestion.name}</div></li>`;
      }
      else {
        return `<li tabindex="0"
        class="results__item"
        data-type="${suggestion.type}"
        data-index="${index}"
        data-id="${suggestion.id}"
        data-name="${suggestion.name}"><div>${suggestion.name}</div></li>`;
      }
    }

    const showDirectoryPromptPanel = () => {
      const html = defaultSuggestions.map((s, index) => buildDefaultSuggestionHTML(s, index)).join('');
      suggestionsTarget.innerHTML = html;
      addHandlersToSuggestions();
      openSuggestionsPanel();
    }

    const showLocationPromptPanel = () => {
      const html = `<li tabindex="0" class="results__item--none" data-location="true" data-index="0">Search Location...</li>`;
      suggestionsTarget.innerHTML = html;
      openSuggestionsPanel();
    }

    const keyHandler = (e) => {
      if (results.length === 0) {
        return
      }
      const currentSelection = getSelectedResult();
      let currentIndex = -1;
      if (currentSelection) {
        currentIndex = parseInt(currentSelection.dataset.index);
      }
      switch (e.key) {
        case 'ArrowDown':
          if (currentIndex < results.length - 1) {
            currentIndex++;
            setSelection(currentIndex);
          }
          break;
        case 'ArrowUp':
          if (currentIndex > -1) {
            currentIndex--;
            setSelection(currentIndex);
          }
          break;
        case 'Enter':
        case 'Return':
          closeSuggestionsPanel();
      }
    }

    const search = async (e) => {
      const query = e.target.value;
      if (query.length === 0) {
        if (e.target.dataset.searchInput === "directory")
          showDirectoryPromptPanel();
        else
          showLocationPromptPanel();
        return
      }

      results = [];
      closeSuggestionsPanel();
      const url = buildQueryString(query);

      const resp = await fetch(url, {
        method: "GET"
      });

      const data = await resp.json();
      results = data.results;

      const searchImpressionTrackingData = data.search_tracking_data;

      if (searchImpressionTrackingData) {
        const impressionTrackingApiResponse = await fetch("https://d1t8xc2i03znwz.cloudfront.net/api/v1/track/search-intent/send", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(searchImpressionTrackingData),
        });
      }

      let html;

      if (results.length > 0) {
        // this checks whether the results come from the API or the location database, since they both return an array of objects, we can check with hasOwnProperty
        // if the results come from the API, they will have a 'type' property
        if (results[0].hasOwnProperty('type')) {
          results = results.slice(0, 7);
          html = results.map((s, index) => buildAPISuggestionHTML(s, index)).join('');
          suggestionsTarget.innerHTML = html;
          addHandlersToSuggestions();
        }
        else {
          html = results.map((s, index) => buildLocationSuggestionHTML(s, index)).join('');
          suggestionsTarget.innerHTML = html;
          addHandlersToSuggestions();
        }
      } else {
        html = `<li class="results__item results__item--none">No Results Found</li>`;
        suggestionsTarget.innerHTML = html;
      }
      openSuggestionsPanel();
    };

    const actions = {
      search: debounce(search, 300),
    };

    controllerElement.querySelectorAll("[data-action]").forEach(el => {
      const actionName = el.dataset.action.split('#')[1];
      el.addEventListener('input', actions[actionName]);
      el.addEventListener('click', actions['search']);

      // TODO: when refactoring this can be specified in the DOM
      el.addEventListener('keyup', keyHandler);
    })

    controllerElement.addEventListener('keyup', e => {
      if (e.key === 'Escape') {
        closeSuggestionsPanel();
      }
    });

  })
